<template>
    <!-- 定制手写组件：select远程搜索组件 -->
    <!-- Auth:Dengwenwen -->
    <!-- Time:2023-10-30 -->
    <!-- 说明：通过输入搜索远程获取option并且返回相应的值 -->
    <el-select v-model="valueData" filterable remote reserve-keyword placeholder="请输入" :remote-method="remoteMethod" :loading="select_loading" clearable @change="changeEvent">
        <el-option v-for="(item, index) in select_options" :key="index" :label="item[keyLabelValue.label]" :value="item[keyLabelValue.value]" />
    </el-select>
</template>
<script>
import { reactive, ref } from 'vue'
import { http } from '@/assets/js'

export default {
    name: 'aw-select-remote',
    props: {
        modelValue: {
            type: String,
            required: true
        },
        select_info: {
            type: Object,
            required: true,
            default() {
                return {
                    axios_type: 'post',
                    axios_url: '',
                    axios_params: { key_value: '', other_value: {} },
                    key_label_value: { label: '', value: 'id' }
                }
            }
        }
    },
    setup(props, { emit }) {
        const valueData = ref(props.modelValue)
        const keyLabelValue = reactive(props.select_info.key_label_value)

        // 远程搜索select
        const select_loading = ref(false)
        const select_options = ref([])
        const remoteMethod = async (query) => {
            if (query) {
                select_loading.value = true

                let result = ''
                let params = reactive({})
                params[props.select_info['axios_params']['key_value']] = query
                params = { ...params, ...props.select_info['axios_params']['other_value'] }
                if (props.select_info['axios_type'] == 'post') {
                    result = await http.post(props.select_info['axios_url'], params)
                } else if (props.select_info['axios_type'] == 'get') {
                    result = await http.get(props.select_info['axios_url'], { params: params })
                }
                select_options.value = result.data
                select_loading.value = false
            } else {
                select_options.value = []
            }
        }

        const changeEvent = (value) => {
            emit('change-data', valueData.value)
        }

        return {
            valueData,
            keyLabelValue,
            select_options,
            remoteMethod,
            changeEvent,
            select_loading
        }
    }
}
</script>
<style lang="scss"></style>
