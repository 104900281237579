import { ref } from 'vue'
import dict from './dict'

const new_roles = ref([])
const mate_name = ref(dict.platform.mate_name)

const tool = {
    // 遍历数组对象获取所有children下的id以及path
    // 主要用于menu菜单与后端的id匹配等
    traverseChildren(arr, result = []) {
        // 遍历数组中的每个对象
        for (const obj of arr) {
            // 如果当前对象有id和path属性
            if (obj.hasOwnProperty('id') && obj.hasOwnProperty('path')) {
                // 将id和path组成新对象，并添加到结果数组中
                result.push({ id: obj.id, path: obj.path })
            }
            // 如果当前对象有children属性且是一个数组
            if (obj.hasOwnProperty('children') && Array.isArray(obj.children)) {
                // 递归调用遍历子级的children属性
                tool.traverseChildren(obj.children, result)
            }
        }

        return result
    },

    // 重新遍历路由
    addPathToChildren(arr, roles) {
        let transformed = []

        arr.forEach((item) => {
            let newItem = {
                icon: item.icon,
                name: item.permission_name,
                path: item.component != '' ? item.component : `a${item.id}`,
                meta: { name: item.permission_name + ' | ' + mate_name.value, role: item.button, id: item.id, roles: roles }
            }
            if (item.component != '') newItem.component = () => import(`@/view${item.component}.vue`)

            if (item.children && item.children.length > 0) {
                newItem.children = tool.addPathToChildren(item.children, roles)
            }

            transformed.push(newItem)
        })

        return transformed
    },

    // 获取所有权限
    getAllPowerlimite(arr) {
        // 遍历所有权限
        arr.forEach((item) => {
            if (item.button) new_roles.value = [...new_roles.value, ...item.button]

            if (item.children && item.children.length > 0) {
                tool.getAllPowerlimite(item.children)
            }
        })

        return new_roles.value
    },

    // 获取数组中的某一个元素,并返回新数组
    getArrayToOneChat(arr, chat) {
        return arr.map((n) => n[chat])
    }
}

export default tool
