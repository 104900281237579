import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../modules'
import { http, tool, dict } from '@/assets/js'
import { ElMessage } from 'element-plus'

const routes = [
    {
        //登录
        path: '/',
        name: 'login',
        meta: { name: dict.platform.meta_name },
        component: () => import('@/view/login/login.vue')
    }
]
// 静态路由
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: [...routes]
})

router.beforeResolve(async (to) => {
    if (to.path == '/') return

    const { user } = store.state
    if (user.login) {
        if (to.matched.length === 0) {
            ElMessage({
                message: '未找到路由，请重新登录！',
                type: 'error',
                duration: 1000,
                onClose: () => {
                    router.push('/')
                    localStorage.clear()
                }
            })
            return false
        }
        document.title = to.meta.name
        return true
    } else {
        const token = to.query.token || user.token
        if (token) {
            let menu_response = await http.get('/admin/adminUser/getPermissions')
            let roles = tool.getAllPowerlimite(menu_response)
            let menu = tool.addPathToChildren(menu_response, roles)
            localStorage.setItem('mymodels', JSON.stringify(menu)) //存储导航信息
            store.commit('user/set_login', { token })
            router.addRoute({
                name: 'index',
                path: '/index',
                meta: { name: '主页' },
                component: () => import('@/view/index/index.vue'),
                children: menu
            })

            router.replace({ path: to.path, query: to.query })

            return true
        } else {
            router.push('/')
            localStorage.clear()
            return true
        }
    }
})

// 路由后
router.afterEach((to) => {
    let name = to.query.meta_name || to.meta.name
    if (name) {
        document.title = name
        for (let key in to.query) {
            const res = parseInt(to.query[key])
            if (!isNaN(res)) to.query[key] = res
        }
    }
})

export default router
