/**
 * v-debounce
 * dom上按钮的防抖指令
 * 接收参数：{ fn: Function, timer: number }
 * click事件监听形式，fn不可传参 否则fn会先执行
 */
export const debounce = {
  mounted(el, binding) {
    const {fn, timer: timeout} = binding.value
    if (typeof fn !== 'function') {
      throw 'callback must be a function';
    }
    let timer = null;
    el.__handleClick__ = function () {
      if (timer) {
        clearInterval(timer);
      }
      timer = setTimeout(() => {
        fn();
      }, timeout);
    };
    el.addEventListener('click', el.__handleClick__);
  },
  beforeUnmount(el) {
    el.removeEventListener('click', el.__handleClick__);
  },
};
