const env_cfg = {
    // 开发环境
    development: {
        // 后端接口
        api: 'https://emall.jingshuoprint.net',
        // api: 'https://dev.jsshareprint.com',
        // oss-bucket文件夹
        bucket_file: 'JSMALL/dev/',
        // oss-目标文件夹-配置
        target_file: { 1: 'picture-library', 100: 'in-common-use' },
        // oss配置
        oss_cfg: {
            region: 'oss-cn-beijing',
            secure: true, // secure: 配合region使用，如果指定了secure为true，则使用HTTPS访问
            accessKeyId: 'LTAI5tDPywVR9EPSh2yoyRP7',
            accessKeySecret: 'b8pkJrJuMlHODknDx0Omxct6FVdxch',
            bucket: 'dev-test-yinboshi' // 阿里云中bucket存储空间类似于存储文件夹，可在其中存放多个文件夹
        }
    },
    // 测试环境
    testing: {
        api: 'https://emall.jsshareprint.com',
        bucket_file: 'JSMALL/test/',
        target_file: { 1: 'picture-library', 100: 'in-common-use' },
        oss_cfg: {
            region: 'oss-cn-beijing',
            secure: true,
            accessKeyId: 'LTAI5tDPywVR9EPSh2yoyRP7',
            accessKeySecret: 'b8pkJrJuMlHODknDx0Omxct6FVdxch',
            bucket: 'dev-test-yinboshi'
        }
    },
    // 生产环境
    production: {
        api: 'https://emall.jsyinba.com',
        bucket_file: 'JSMALL/prod/',
        target_file: { 1: 'picture-library', 100: 'in-common-use' },
        oss_cfg: {
            region: 'oss-cn-beijing',
            secure: true,
            accessKeyId: 'LTAI5tDPywVR9EPSh2yoyRP7',
            accessKeySecret: 'b8pkJrJuMlHODknDx0Omxct6FVdxch',
            bucket: 'zhihuituwen'
        }
    }
}

export default {
    ...env_cfg[process.env.NODE_ENV],
    // Authentication认证字段
    Authentication: 'token'
    // Authentication: 'Authentication'
}
