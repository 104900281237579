import store from './index'
const local = localStorage.getItem('token') || ''

store.registerModule('user', {
    namespaced: true,
    state: {
        login: false,
        token: local,
        menus: [],
        user: null
    },
    mutations: {
        set_login(state, { token }) {
            state.login = true
            state.token = token
            localStorage.setItem('token', token)
        },
        set_loginout(state) {
            state.login = false
            state.token = null
            localStorage.removeItem('token')
        }
    },
    actions: {}
})
