<template>
    <div class="bg-white p-l-12 p-r-12 p-b-12 brd-px-4 m-t-20">
        <slot name="table_tab" />
        <div class="flex-r flex-jc-sb flex-ai-c flex-wrap flex-gap-20 h-px-min-40 p-t-8">
            <div class="f-12 c-666 h-px-min-40 l-h-40 flex-1">
                <slot name="table_title">
                    <span>{{ title }}</span>
                </slot>
            </div>
            <div>
                <slot name="table_extras"></slot>
            </div>
        </div>
        <div flex="dir:top box:last" style="white-space: pre-wrap; margin-top: 8px">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'aw-card',
    props: {
        title: {
            type: String
        }
    }
}
</script>
